<template>
    <v-container class="container-dark">

        <!-- Main Card -->
        <v-card class="main-card-announcement mb-5">

            <template>
                <v-container>
                    
                    <!-- Row Level 1 -->
                    <v-row>

                        <!-- Main Slide -->
                        <v-col cols="12" md="8">
                            <v-card
                            dense
                            :height="$vuetify.breakpoint.smAndDown ? 'auto' : '460'"
                            >
                                <v-carousel
                                :cycle="pusing"
                                :height="$vuetify.breakpoint.smAndDown ? 'auto' : '460'"
                                hide-delimiters
                                show-arrows-on-hover
                                style="cursor: pointer"
                                >
                                    <v-carousel-item
                                    v-for="(item, i) in announcement"
                                    :key="i"
                                    @click="viewAnnouncement(item)"
                                    height="auto"
                                    :style="$vuetify.theme.dark == false ?'background:#dee2e6;' : 'background:#323232;'"
                                    >
                                        <v-container
                                        fill-height
                                        fluid
                                        pa-0 ma-0 pb-0 
                                        v-if="item.title != null && item.title != ''"
                                        >
                                            
                                            <v-layout fill-height align-end pb-0 mb-0>
                                                <v-flex xs12>
                                                    <v-img :src="item.picture"></v-img>
                                                <v-card tile elevation="0" class="pa-2 cardColor text-center">
                                                    <strong class="text-capitalize" v-text="item.title"></strong>
                                                </v-card>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-carousel-item>

                                </v-carousel>
                            </v-card>
                        </v-col>

                        <!-- Section Main Right -->
                        <v-col cols="12" md="4">

                            <!-- Upcoming Holidays -->
                            <v-row>
                                <v-col>
                                    <v-card dense class="box-holiday">
                                        <v-list-item>
                                            <v-icon class="mr-2 blackk--text">mdi-calendar-multiple-check</v-icon>
                                            <v-list-item-content>
                                                <!-- <v-list-item-title class="text-h5 mb-1">
                                                Upcoming Holidays
                                                </v-list-item-title> -->
                                                <div class="overline text-bold" style="font-size:1em !important;">
                                                    Upcoming Holidays
                                                </div>
                                            </v-list-item-content>

                                            
                                        </v-list-item>

                                        <v-container class="pt-0">
                                            <template>
                                                <v-simple-table
                                                dense
                                                height="110"
                                                class="table-holiday"
                                                fixed-header
                                                >
                                                    <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                        <th class="text-left">
                                                            Holidays
                                                        </th>
                                                        <th class="text-left">
                                                            Start Date
                                                        </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="holidays.length > 0">
                                                        <tr
                                                        v-for="item in holidays"
                                                        :key="item.name"
                                                        >
                                                            <td>
                                                                <span class="caption text-bold">
                                                                    {{ item.name }}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span class="caption text-bold">
                                                                    {{convertDateString(item.start)}}
                                                                </span>
                                                                <!-- {{ (convertDateString(item.start) == convertDateString(item.end) ? convertDateString(item.start) : 
                                                                convertDateString(item.start) + ' - ' + convertDateString(item.end)) }} -->
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="2">
                                                                No Upcoming Holiday
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </template>
                                        </v-container>
                                    </v-card>
                                </v-col>

                                <!-- <v-spacer></v-spacer> -->

                                <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="primary"
                                    elevation="2"
                                    fab
                                    small
                                    absolute
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                        <v-icon>mdi-calendar-multiple</v-icon>
                                    </v-btn>
                                </template>
                                <span>Events Summary</span>
                                </v-tooltip> -->

                            </v-row>

                            <!-- Maklumat Tambahan dan date -->
                            <v-row dense>
                                <v-col>
                                    <v-card class="box-today">
                                        <v-card-text class="py-2">
                                            <small class="font-weight-bold overline todayAnnc--text" style="font-size:0.8em !important;">
                                                <v-icon class="todayAnnc--text" small >mdi-calendar</v-icon>
                                                Today
                                            </small>
                                            <div v-html="todayFullDate(todayDate)" class="overline primary--text" style="font-size:1.5em !important;"></div>
                                            <div dense class="overline blackk--text">
                                                <span dense v-html="writeIslamicDate()"></span>
                                            </div>

                                            <v-divider></v-divider>

                                            <!-- <div class="font-weight-bold overline mt-2" style="font-size:1em !important;">
                                                <v-icon small >mdi-file-document-multiple-outline</v-icon>
                                                Documents
                                            </div> -->
                                            <div style="height: 162px; overflow-y: auto;">
                                                <template>
                                                    <v-treeview
                                                        v-model="tree"
                                                        :items="items"
                                                        activatable
                                                        item-key="name"
                                                        open-on-click
                                                        dense
                                                        @update:active="openPDF"
                                                    >
                                                    <!-- letak ni as prop in v-treeviewm untuk auto open folder first load --> :open="initiallyOpen" -->
                                                        <template v-slot:prepend="{ item, open }">
                                                            <v-icon :color="item.color" dense v-if="!item.file">
                                                                {{ open ? item.iconOpen : item.iconClose }}
                                                            </v-icon>
                                                            <v-icon  :color="item.color" dense v-else>
                                                                {{ files[item.file] }}
                                                            </v-icon>
                                                        </template>

                                                    </v-treeview>
                                                </template>
                                                
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                        </v-col>

                    </v-row>


                    <!-- Row Level 2 -->
                    <v-row>

                        <!-- List News -->
                        <v-col class="pt-0">
                            <template>
                                <strong class="ml-2 subtitle-2 text-bold">
                                    <v-icon class="mr-1">mdi-newspaper-variant-multiple-outline</v-icon>
                                    Latest News:
                                </strong>
                                
                                <v-sheet
                                class="mx-auto"
                                elevation="0"
                                >
                                    <v-slide-group
                                    class="pa-0"
                                    active-class="success"
                                    show-arrows
                                    >
                                    <v-slide-item
                                        v-for="(item, i) in dataNews"
                                        :key="i"
                                    >
                                        <v-card
                                        class="ma-4 pa-2 text-left"
                                        height="auto"
                                        width="180"
                                        tile
                                        @click="viewNews(item)"
                                        >   
                                            
                                            <v-icon dense class="darkk-text mr-2">mdi-calendar</v-icon>
                                            <small class="pa-0 text-bold caption" v-html="convertDateString(item.dateApplied)"></small>

                                            <v-img tile width="auto" height="65" class="mt-2" :src="item.picture"></v-img>

                                            <small class="pa-0 caption font-weight-regular" v-html="item.title"></small><br>
                                            
                                            
                                        </v-card>
                                    </v-slide-item>
                                    </v-slide-group>
                                </v-sheet>
                            </template>
                        </v-col>

                    </v-row>

                    

                </v-container>
            </template>

            <v-progress-linear
                :active="loadingThisPage"
                :indeterminate="loadingThisPage"
                absolute
                bottom
                color="primary accent-4"
            ></v-progress-linear>
            
        </v-card>


        <!-- Section Dialog Popup -->

        <!-- Dialog Announcement -->
        <template>
            <v-row justify="center">
                <v-dialog
                persistent
                v-model="dialogAnnouncements"
                scrollable
                max-width="650px"
                >
                    <v-card>
                        <v-card-title class="justify-center title-bar-modul">Announcement</v-card-title>
                        <v-card-text>
                            <v-row>

                                <v-col cols="12" class="pb-0 mb-0">
                                    <v-icon class="mr-2 primary--text">mdi-calendar</v-icon>
                                    <strong class="overline text-bold primary--text py-0 my-0">{{ convertDateString(displayAnnouncement.dateApplied) }}</strong>
                                </v-col>
                                <v-col
                                    class="d-flex"
                                    cols="12"
                                >
                                    <v-img
                                        :src="displayAnnouncement.picture"
                                        aspect-ratio="1"
                                        class="grey lighten-2"
                                        width="auto"
                                        :height="$vuetify.breakpoint.smAndDown ? '170' : '300'"
                                    >
                                    </v-img>
                                </v-col>
                                <v-col
                                    class="d-flex"
                                    cols="12"
                                    sm="12"
                                >
                                    <h3>
                                        {{ displayAnnouncement.title }}
                                    </h3>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                >
                                    <span v-html="displayAnnouncement.details">

                                    </span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="primary--text text-bold"
                                text
                                @click="dialogAnnouncements = false; pusing= true;"
                            >
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Dialog News -->
        <template>
            <v-row justify="center">
                <v-dialog
                persistent
                v-model="dialogNews"
                scrollable
                max-width="650px"
                >
                    <v-card>
                        <v-card-title class="justify-center title-bar-modul">Information</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" class="pb-0 mb-0">
                                    <v-icon class="mr-2 primary--text">mdi-calendar</v-icon>
                                    <strong class="overline text-bold primary--text py-0 my-0">{{ convertDateString(displayNews.dateApplied) }}</strong>
                                </v-col>
                                <v-col
                                    class="d-flex"
                                    cols="12"
                                >
                                    <v-img
                                        :src="displayNews.picture"
                                        aspect-ratio="1"
                                        class="grey lighten-2"
                                        width="auto"
                                        :height="$vuetify.breakpoint.smAndDown ? '170' : '300'"
                                    >
                                    </v-img>
                                </v-col>
                                <v-col
                                    class="d-flex"
                                    cols="12"
                                    sm="12"
                                >
                                    <h3>
                                        {{ displayNews.title }}
                                    </h3>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                >
                                    <span v-html="displayNews.details">

                                    </span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="primary--text text-bold"
                                text
                                @click="dialogNews = false"
                            >
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

    </v-container>
</template>


<script>
import axios from 'axios';

export default {

    data: () => ({

        loadingThisPage: false,

        // ADDITIONAL INFORMATION BOX
        todayDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        initiallyOpen: ['Documents','User Manual'],
        files: {
            html: 'mdi-language-html5',
            js: 'mdi-nodejs',
            json: 'mdi-code-json',
            md: 'mdi-language-markdown',
            pdf: 'mdi-file-pdf-box',
            png: 'mdi-file-image',
            txt: 'mdi-file-document-outline',
            xls: 'mdi-file-excel',
            web: 'mdi-web',
        },
        tree: [],
        items: [
            {
                name: 'Office Documents',
                children: [
                    {
                        name: 'MNJ Employee Handbook.pdf',
                        file: 'pdf',
                        url: require("@/assets/pdf/mnj-employee-handbook.pdf"),
                        color: 'red',
                        fileName: 'MNJ Employee Handbook.pdf'
                    },
                    // {
                    //     name: 'Claim Request Form.pdf',
                    //     file: 'pdf',
                    //     url: require("@/assets/pdf/claim-request-form.pdf"),
                    //     color: 'red',
                    //     fileName: 'Claim Request Form.pdf'
                    // },
                    {
                        name: 'Claim And Advance Form.pdf',
                        file: 'pdf',
                        url: require("@/assets/pdf/FORM-Claim-n-Advance.pdf"),
                        color: 'red',
                        fileName: 'Claim And Advance Form.pdf'
                    },
                    {
                        name: 'Purchase, Invoice, And Payment Request Form.pdf',
                        file: 'pdf',
                        url: require("@/assets/pdf/FORM-Purchase-Invoice-Payment-Request.pdf"),
                        color: 'red',
                        fileName: 'Purchase, Invoice, And Payment Request Form.pdf'
                    },
                ],
                color: 'orange',
                iconOpen: 'mdi-folder-open',
                iconClose: 'mdi-folder'
            },
            {
                name: 'MEMS User Manual',
                file: 'web',
                url: 'https://dev2.serasi.tech/index.html',
                // children: [
                //     {
                //         name: 'MEMS User Manual',
                //         file: 'html',
                //         url: 'https://dev2.serasi.tech/index.html',
                //         color: 'red',
                //         fileName: 'MEMS User Manual'
                //     },
                // ],
                color: 'primary',
                // iconOpen: 'mdi-web-minus',
                // iconClose: 'mdi-web-plus'
            },
        ],

        // DIALOG
        dialogAnnouncements: false,
        dialogNews: false,

        // MAIN SLIDE ANNOUNCEMENT
        colors: ['indigo','warning','pink darken-2','red lighten-1','deep-purple accent-4'],
        slides: ['First','Second','Third','Fourth','Fifth'],
        displayAnnouncement: {
            title: '',
            details: '',
            picture: '',
            dateApplied: '',
        },
        announcement: [
            {
                title: '',
                picture: require("@/assets/img/others/mnjSlide.jpg")
            },
        ],
        pusing: true,


        //UPCOMING HOLIDAYS
         holidays: [
            //  {"name":"Hari Nuzul Al-Quran","start":"2022-04-19T00:00:00","end":"2022-04-19T00:00:00","color":"red","timed":"true","countdays":1},
            //  {"name":"Hari Nuzul Al-Quran","start":"2022-04-19T00:00:00","end":"2022-04-19T00:00:00","color":"red","timed":"true","countdays":1},
            //  {"name":"Hari Nuzul Al-Quran","start":"2022-04-19T00:00:00","end":"2022-04-19T00:00:00","color":"red","timed":"true","countdays":1},
            //  {"name":"Hari Nuzul Al-Quran","start":"2022-04-19T00:00:00","end":"2022-04-19T00:00:00","color":"red","timed":"true","countdays":1},
            //  {"name":"Hari Nuzul Al-Quran","start":"2022-04-19T00:00:00","end":"2022-04-19T00:00:00","color":"red","timed":"true","countdays":1},
            //  {"name":"Hari Nuzul Al-Quran","start":"2022-04-19T00:00:00","end":"2022-04-19T00:00:00","color":"red","timed":"true","countdays":1},
         ],


        // LIST NEWS
        displayNews: {
            title: '',
            details: '',
            picture: '',
            dateApplied: '',
        },
        dataNews: [],

    }),
    methods: {

        // GENERAL
        openPDF(item){
            console.log(item);

            for(let i in this.items){

                if (item == "MEMS User Manual") {
                    window.open("https://dev2.serasi.tech/index.html");
                }
                else{
                    for(let x in this.items[i].children){
                        if (this.items[i].children[x].fileName == item[0]) {
                            window.open(this.items[i].children[x].url);
                        }
                    }
                }
                
            }
        },

        // MAIN SLIDE ANNOUNCEMENT
        getAllAnnouncement(){

            this.loadingThisPage = true;

            let dtToday = new Date(Date.now());
            let thisYear = dtToday.getFullYear()

            axios.get(process.env.VUE_APP_API_URL + 'announce/all?startdt='+thisYear+'-01-01&enddt='+thisYear+'-12-31', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                let data = response.data;
                let dataAnnouncement = [];
                for(let i in data){
                    if (data[i].type == "A") {
                        dataAnnouncement.push(data[i]);
                    }
                }
                this.announcement = dataAnnouncement.slice(0, 5); //5 slide at a time
                this.loadingThisPage = false;
            })
            .catch(error => {
                console.log(error.response);
                this.loadingThisPage = false;
            })
        },

        viewAnnouncement(item){
            this.pusing = false;
            this.displayAnnouncement.title = item.title;
            this.displayAnnouncement.details = item.details;
            this.displayAnnouncement.picture = item.picture;
            this.displayAnnouncement.dateApplied = item.dateApplied;
            this.dialogAnnouncements = true;
        },


        //UPCOMING HOLIDAYS
        getAllHoliday(){
            axios.get(process.env.VUE_APP_API_URL + 'cuti/publicholiday', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                const holidays = response.data;
                const thisMonth = new Date().getMonth();
                let nextMonth = new Date().getMonth() + 1;
                nextMonth == 12? nextMonth = 0 : nextMonth

                // console.log(new Date());
                // console.log(thisMonth);
                // console.log(nextMonth);

                // console.log(holidays);
                // console.log(this.holidays);
                // console.log(today); 

                for(let i = 0; i < holidays.length; i++){
                    if(thisMonth == new Date(holidays[i].start).getMonth()){
                        // this.holidays.push(holidays[i]);
                        
                        // Kalau hari cuti tu dah lepas dari current datetime, remove..
                        if (Date.parse(new Date(holidays[i].end)) - Date.parse(new Date()) > 0) {
                            holidays[i].startdate = new Date(holidays[i].start);
                            this.holidays.push(holidays[i]);
                        }
                    }
                    if(nextMonth == new Date(holidays[i].start).getMonth()){
                        // this.holidays.push(holidays[i]);

                        if (Date.parse(new Date(holidays[i].end)) - Date.parse(new Date()) > 0) {
                            holidays[i].startdate = new Date(holidays[i].start); 
                            this.holidays.push(holidays[i]);
                        }

                        // holidays[i].startdate = new Date(holidays[i].start); 
                        // this.holidays.push(holidays[i]);

                        
                    }
                }
                this.holidays.sort((a, b) => a.startdate - b.startdate);
                // console.log(this.holidays);
                // const myJSON = JSON.stringify(this.holidays);
                // console.log(myJSON);

                // Kalau hari cuti tu dah lepas dari current datetime, remove..
                // for(let i in this.holidays){
                //     if (Date.parse(new Date(this.holidays[i].end)) - Date.parse(new Date()) < 0) {
                //         this.holidays.splice(i, 1); 
                //     }
                // }

            })
            .catch(error => {
                console.log(error.response);
            })
        },


        // LIST NEWS
        getAllNews(){

            let dtToday = new Date(Date.now());
            let thisYear = dtToday.getFullYear()

            axios.get(process.env.VUE_APP_API_URL + 'announce/all?startdt='+thisYear+'-01-01&enddt='+thisYear+'-12-31', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                let data = response.data;
                this.dataNews = data.slice(0, 10)
            })
            .catch(error => {
                console.log(error.response);
            })
        },

        viewNews(item){
            this.displayNews.title = item.title;
            this.displayNews.details = item.details;
            this.displayNews.picture = item.picture;
            this.displayNews.dateApplied = item.dateApplied;
            this.dialogNews = true;
        },

    },
    mounted(){
        this.getAllAnnouncement();
        this.getAllHoliday();
        this.getAllNews();
    },
    computed: {

    }
}
</script>

<style lang="scss">
@import '~scss/main';


.cardColor {
    // #ffffff00 = Transparent maybe
    // background-color: rgba(255, 255, 255, 0.5) !important;
    background-image: linear-gradient(to top, #d2d7dd, #f2f6f7) !important;
    border-color: white !important;
}

.pa-2.cardColor.text-center.v-card.v-sheet.theme--dark.elevation-0.rounded-0 {
    // #ffffff00 = Transparent maybe
    // background-color: rgba(255, 255, 255, 0.5) !important;
    background-image: linear-gradient(to top, #121212, #363636) !important;
    border-color: white !important;
}

.box-today.v-card.v-sheet.theme--light{
    background-image: url("~@/assets/img/others/bg-today4.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.box-holiday.v-card.v-sheet.theme--light{
    background-image: url("~@/assets/img/others/bg-calendar.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.box-today.v-card.v-sheet.theme--dark{
    background-image: url("~@/assets/img/others/bg-today-dark-1.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.box-holiday.v-card.v-sheet.theme--dark{
    background-image: url("~@/assets/img/others/bg-calendar-dark2.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.table-holiday{
    background: #ffffff00 !important;
}

.table-holiday > .v-data-table__wrapper > table > tbody > tr > td{
    font-weight: bold !important;
}

.table-holiday > .v-data-table__wrapper > table > thead > tr > th{
    background: #505c60 !important;
    color: white !important;
}


</style>